@import "variables";

@font-face {
  font-family: PixelOperator;
  src: url('/assets/fonts/PixelOperator.ttf') format('truetype');
}

html, body {
  margin: 0;
  font-family: PixelOperator, monospace;
  color: white;
  background: black;
  font-size: 16px;
  line-height: 15px;
  overflow: hidden;
}

div {
  //font-size: 15px;
}
